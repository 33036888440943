import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 15rem;
  position: relative; /* Ensure it's properly positioned */

  h2 {
    text-align: center;
    font-size: 4rem;
    margin-bottom: 3rem;
    position: relative; /* Adjust if needed */
  }

  .events-container {
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 2rem;
  }

  .events {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    gap: 2rem;
    padding: 1rem;
    min-width: 1000px; /* Adjust this width according to your content */
  }
  
  .event {
    min-width: 300px;
    padding: 2rem 1.8rem;
    background-color: var(--tile-bg);
    border-radius: 1.2rem;
    transition: 0.25s;
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    color: var(--tile-text);

    &:hover {
      transform: translateY(-5px);
      background-color: var(--tile-hover-bg);
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--header-color);
      margin-bottom: 2rem;
    }

    .event-image {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 0.8rem;
      margin-bottom: 1.5rem;
    }

    h3 {
      margin-bottom: 1.5rem;
    }

    p {
      letter-spacing: 0.12rem;
      margin-bottom: 1.5rem;
    }

    span {
      font-weight: bold;
      color: var(--highlight-color);
    }
  }

  @media (max-width: 960px) {
    .events {
      gap: 1.5rem;
    }

    .event {
      min-width: 260px;
    }

    .event-image {
      height: 180px;
    }
  }

  @media (max-width: 740px) {
    .events {
      grid-template-columns: 1fr; /* Single column layout */
      min-width: 100%; /* Make sure it occupies full width */
    }

    .event {
      min-width: 100%; /* Make each tile occupy full width */
    }

    .event-image {
      height: 160px;
    }
  }
`;
