import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import sihImage from '../../assets/sih.jpg';
import Ide from '../../assets/ide.jpg';
import Idea from '../../assets/idea.jpg';
import Ignite from '../../assets/ignite.jpg';
import Spangler from '../../assets/spangler.jpg';
import Startup from '../../assets/tn.png';

export function Events() {
  const events = [
    {
      title: "Ignite Incubator Program",
      description: "A conference for developers focusing on modern web development practices.",
      date: "June, 2024",
      image: Ignite,
    },
    {
      title: "Mentorship Support - StartupTN",
      description: "A conference for developers focusing on modern web development practices.",
      date: "From July, 2024",
      image: Startup, 
    }, 
    {
      title: "SIH 2023",
      description: "An international technology exposition showcasing the latest in AI, IoT, and robotics.",
      date: "December, 2023",
      image: sihImage, 
    },
    {
      title: "IDE Bootcamp AICTE",
      description: "A summit discussing the advancements and ethical implications of AI technology.",
      date: "February, 2024",
      image: Ide, 
    },
    {
      title: "Idea Harvest by TITAN",
      description: "A conference for developers focusing on modern web development practices.",
      date: "April, 2024",
      image: Idea, 
    },
    {
      title: "Spangler Business Contest",
      description: "A conference for developers focusing on modern web development practices.",
      date: "August, 2024",
      image: Spangler, 
    },
  ];

  return (
    <Container id="events">
      <h2>Achievements</h2>
      <div className="events-container">
      <div className="events">
        {events.map((event, index) => (
          <ScrollAnimation key={index} animateIn="fadeInRight">
            <div className="event">
              {/*<header>
                <svg width="50" xmlns="http://www.w3.org/3000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#17726d" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"> <title>Calendar</title> <path d="M21 10H3m18-4H3m18 8H3m18 4H3m2-8v10M19 6v10" /></svg>
              </header>*/}
              <img src={event.image} alt={event.title} className="event-image" />
              <div className="body">
                <h3>{event.title}</h3>
                {/*<p>{event.description}</p>*/}
                <span>{event.date}</span>
              </div>  
            </div>
          </ScrollAnimation>
        ))}
      </div>
      </div>
    </Container>
  );
}
